import React, { useEffect, useState } from 'react';

const FilterNetwork = (props) => {
    
    const [net, setNet] = useState('');
    const [icons, setIcons] = useState([]);

    useEffect(() => {
        setNet(new URLSearchParams(window.location.search).get('network'));
    }, []);

    useEffect(()=> {

        const getIcons = async ()  => {
        
            // networks to promote in front of the list
            let promote = ['Ethereum','Blast','Polygon','BSC','Optimism','Arbitrum One','zkSync','TON'];
    
            // icons to remove from object
            const remove = ['default','Coingecko','Coinmarketcap','Discord','Facebook','Github','Instagram','Linkedin','Medium','Reddit','Telegram','Tiktok','Twitter','Web','Youtube'];
        
            if (net) {
                const curnet = net.replace("+"," ").toLowerCase();
    
                // get icons in lower case to match url parameter
                const iconsLower = Object.fromEntries(Object.keys(props.icons).map(i => {return [i.toLowerCase(),props.icons[i]]}));
                const curnetIconLower = Object.fromEntries(Object.entries(iconsLower).filter(item => [curnet].indexOf(item[0]) > -1));
                const curnetIcon = Object.fromEntries(Object.entries(props.icons).filter(item => [curnetIconLower[curnet]].indexOf(item[1]) > -1));
    
                // update promotion with current network
                if(Object.keys(curnetIcon).length) {
                    promote = [Object.keys(curnetIcon)[0], ...promote];
                }
            }
    
            const promoteIcons = Object.fromEntries(Object.keys(promote).map((v) => (props.icons[promote[v]]) ? [promote[v],props.icons[promote[v]]] : [v,promote[v]] ));
    
            const leftIcons = Object.fromEntries(Object.entries(props.icons).filter(item => [...promote,...remove].indexOf(item[0]) === -1));
    
            setIcons({
                ...promoteIcons,
                ...leftIcons
            });
            
        }
        getIcons();
    }, [props.icons, net]);

    return (
        <div className="filter-nets">
            <label className="filter-tag first">
            <input type="radio" name="category" value="all" onClick={() => props.filterByNet()} onChange={e => {}} checked={(!props.curnet)} /><span className="btn btn-gray">All</span>
            </label>
            { Object.entries(icons).slice(0,props.loadnets).map(([key,val],id) => (
                <label className="filter-tag" key={id}>
                <input type="radio" name="category" value={key} onClick={() => props.filterByNet(key)} onChange={e => {}} checked={(props.curnet === key.toLowerCase())} /><span className="btn btn-gray"><i className={"svg-"+key.replace(" ","-").replace(".","-").toLowerCase()} title={key}>{key}</i>  {key.replace("Arbitrum One","Arbitrum")}</span>
                </label>
                ))
            }
            { ( Object.entries(icons).length > props.loadnets ) ? (
                <label className="filter-tag last">
                <input type="radio" name="morefilters" value="+" onClick={(e) => { e.preventDefault(); props.moreFilters(props.loadnets + 22);}} onChange={e => {}} /><span className="btn btn-gray">+</span>
                </label>
            ) : null }
        </div>
    );
};
export default FilterNetwork;